.animated-check_animatedCheck__0CrzN {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  background-color: transparent;
  padding: 0.25rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  z-index: 1;
}

.animated-check_animatedCheck__0CrzN.animated-check_md__ko1ci {
  padding: 0.375rem;
}

.animated-check_animatedCheck__0CrzN.animated-check_lg__axN7b {
  padding: 0.5rem;
}

@media screen and (prefers-reduced-motion: reduce) {
  .animated-check_animatedCheck__0CrzN.animated-check_green__aY07z {
    visibility: visible;
  }
  .animated-check_animatedCheck__0CrzN.animated-check_green__aY07z {
    --tw-border-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-border-opacity));
  }
  .animated-check_animatedCheck__0CrzN.animated-check_green__aY07z {
    --tw-bg-opacity: 1;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity));
  }

  .animated-check_animatedCheck__0CrzN.animated-check_primary__ni16H {
    --tw-border-opacity: 1;
    border-color: rgb(66 164 255 / var(--tw-border-opacity));
  }

  .animated-check_animatedCheck__0CrzN.animated-check_primary__ni16H {
    --tw-bg-opacity: 1;
    background-color: rgb(66 164 255 / var(--tw-bg-opacity));
  }

  .animated-check_animatedCheck__0CrzN.animated-check_transparent__Ojegk {
    border-color: transparent;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_transparent__Ojegk {
    background-color: transparent;
  }
}

@media screen and (prefers-reduced-motion: no-preference) {
  .animated-check_animatedCheck__0CrzN.animated-check_animate__dk7m5.animated-check_green__aY07z {
    -webkit-animation: animated-check_check__fo_oE 1s linear forwards;
            animation: animated-check_check__fo_oE 1s linear forwards;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_animate__dk7m5.animated-check_primary__ni16H {
    -webkit-animation: animated-check_checkPrimary__su0_t 1s linear forwards;
            animation: animated-check_checkPrimary__su0_t 1s linear forwards;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_animate__dk7m5.animated-check_transparent__Ojegk {
    -webkit-animation: animated-check_checkTransparent__apYWb 1s linear forwards;
            animation: animated-check_checkTransparent__apYWb 1s linear forwards;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_animate__dk7m5.animated-check_green__aY07z::before {
    position: absolute;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_animate__dk7m5.animated-check_green__aY07z::before {
    height: 100%;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_animate__dk7m5.animated-check_green__aY07z::before {
    width: 100%;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_animate__dk7m5.animated-check_green__aY07z::before {
    border-radius: 9999px;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_animate__dk7m5.animated-check_green__aY07z::before {
    --tw-bg-opacity: 1;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity));
  }

  .animated-check_animatedCheck__0CrzN.animated-check_animate__dk7m5.animated-check_green__aY07z::before {
    opacity: 0.4;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_animate__dk7m5.animated-check_green__aY07z::before {
    content: "";
    z-index: -1;
    -webkit-animation: animated-check_fade__f0WAf 1s linear forwards;
            animation: animated-check_fade__f0WAf 1s linear forwards;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_animate__dk7m5.animated-check_primary__ni16H::before {
    --tw-bg-opacity: 1;
    background-color: rgb(66 164 255 / var(--tw-bg-opacity));
  }

  .animated-check_animatedCheck__0CrzN.animated-check_delay1__hZozD {
    -webkit-animation-delay: 200ms !important;
            animation-delay: 200ms !important;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_delay2__CwFL2 {
    -webkit-animation-delay: 400ms !important;
            animation-delay: 400ms !important;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_delay3__Nq_JA {
    -webkit-animation-delay: 600ms !important;
            animation-delay: 600ms !important;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_delay4__Ddo__ {
    -webkit-animation-delay: 800ms !important;
            animation-delay: 800ms !important;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_delay5__lVA1W {
    -webkit-animation-delay: 1000ms !important;
            animation-delay: 1000ms !important;
  }

  .animated-check_animatedCheck__0CrzN.animated-check_delay6___jRQr {
    -webkit-animation-delay: 1200ms !important;
            animation-delay: 1200ms !important;
  }
}

@-webkit-keyframes animated-check_check__fo_oE {
  0% {
    visibility: visible;
  }
  0% {
    transform: scale(0.8);
  }
  20% {
    transform: scale(1.2);
  }
  20% {
    --tw-border-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-border-opacity));
  }
  20% {
    --tw-bg-opacity: 1;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity));
  }
  50% {
    transform: scale(0.9);
  }
  60%, 100% {
    visibility: visible;
  }
  60%,
  100% {
    transform: scale(1);
  }
  60%, 100% {
    --tw-border-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-border-opacity));
  }
  60%, 100% {
    --tw-bg-opacity: 1;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity));
  }
}

@keyframes animated-check_check__fo_oE {
  0% {
    visibility: visible;
  }
  0% {
    transform: scale(0.8);
  }
  20% {
    transform: scale(1.2);
  }
  20% {
    --tw-border-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-border-opacity));
  }
  20% {
    --tw-bg-opacity: 1;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity));
  }
  50% {
    transform: scale(0.9);
  }
  60%, 100% {
    visibility: visible;
  }
  60%,
  100% {
    transform: scale(1);
  }
  60%, 100% {
    --tw-border-opacity: 1;
    border-color: rgb(52 211 153 / var(--tw-border-opacity));
  }
  60%, 100% {
    --tw-bg-opacity: 1;
    background-color: rgb(52 211 153 / var(--tw-bg-opacity));
  }
}

@-webkit-keyframes animated-check_checkPrimary__su0_t {
  0% {
    visibility: visible;
  }
  0% {
    transform: scale(0.8);
  }
  20% {
    transform: scale(1.2);
  }
  20% {
    --tw-border-opacity: 1;
    border-color: rgb(66 164 255 / var(--tw-border-opacity));
  }
  20% {
    --tw-bg-opacity: 1;
    background-color: rgb(66 164 255 / var(--tw-bg-opacity));
  }
  50% {
    transform: scale(0.9);
  }
  60%, 100% {
    visibility: visible;
  }
  60%,
  100% {
    transform: scale(1);
  }
  60%, 100% {
    --tw-border-opacity: 1;
    border-color: rgb(66 164 255 / var(--tw-border-opacity));
  }
  60%, 100% {
    --tw-bg-opacity: 1;
    background-color: rgb(66 164 255 / var(--tw-bg-opacity));
  }
}

@keyframes animated-check_checkPrimary__su0_t {
  0% {
    visibility: visible;
  }
  0% {
    transform: scale(0.8);
  }
  20% {
    transform: scale(1.2);
  }
  20% {
    --tw-border-opacity: 1;
    border-color: rgb(66 164 255 / var(--tw-border-opacity));
  }
  20% {
    --tw-bg-opacity: 1;
    background-color: rgb(66 164 255 / var(--tw-bg-opacity));
  }
  50% {
    transform: scale(0.9);
  }
  60%, 100% {
    visibility: visible;
  }
  60%,
  100% {
    transform: scale(1);
  }
  60%, 100% {
    --tw-border-opacity: 1;
    border-color: rgb(66 164 255 / var(--tw-border-opacity));
  }
  60%, 100% {
    --tw-bg-opacity: 1;
    background-color: rgb(66 164 255 / var(--tw-bg-opacity));
  }
}

@-webkit-keyframes animated-check_checkTransparent__apYWb {
  0% {
    visibility: visible;
  }
  0% {
    transform: scale(0.8);
  }
  20% {
    transform: scale(1.2);
  }
  20% {
    border-color: transparent;
  }
  20% {
    background-color: transparent;
  }
  50% {
    transform: scale(0.9);
  }
  60%, 100% {
    visibility: visible;
  }
  60%,
  100% {
    transform: scale(1);
  }
  60%, 100% {
    border-color: transparent;
  }
  60%, 100% {
    background-color: transparent;
  }
}

@keyframes animated-check_checkTransparent__apYWb {
  0% {
    visibility: visible;
  }
  0% {
    transform: scale(0.8);
  }
  20% {
    transform: scale(1.2);
  }
  20% {
    border-color: transparent;
  }
  20% {
    background-color: transparent;
  }
  50% {
    transform: scale(0.9);
  }
  60%, 100% {
    visibility: visible;
  }
  60%,
  100% {
    transform: scale(1);
  }
  60%, 100% {
    border-color: transparent;
  }
  60%, 100% {
    background-color: transparent;
  }
}

@-webkit-keyframes animated-check_fade__f0WAf {
  50%,
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

@keyframes animated-check_fade__f0WAf {
  50%,
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

